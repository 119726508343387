import React from 'react';
import {css} from 'emotion';
import Helmet from 'react-helmet';
import {cancelRide, rideCancelled, flushOrderState, cancelInitiate} from '../actions/orderActions';
import Header from '../components/appComponents/Header';
import AppLayout from '../components/appComponents/AppLayout';
import AccordionItem from '../components/appComponents/AccordionItem';
import { connect } from 'react-redux';
import Moment from 'moment';
import { navigate } from 'gatsby';
import { flushCarsState } from '../actions/carsActions';
import { flushCouponState } from '../actions/couponActions';
import { flushRideState } from '../actions/rideActions';
import { flushTripState } from '../actions/tripActions';
import { fetchProfile } from '../actions/userActions';
// import Menu from '../components/appComponents/Menu';
import Popup from 'reactjs-popup'
import PaymentRadio from '../components/appComponents/PaymentRadio';
import ReactPixel from 'react-facebook-pixel';

/* global tw */
const styles = {
    listWrapper: css`
        ${tw`w-full bg-white overflow-y-scroll scrolling-touch`};
        border-radius: 16px 16px 0 0;
        height: 91.4511742891vh;
        // transform: translateY(-1vh);
        padding-bottom: 100px;
        box-shadow: 0 0 25px -2px rgba(0,0,0,0.3);
         -webkit-overflow-scrolling: touch;
    `,
    row: css`
        ${tw`w-full flex flex-wrap justify-between items-center px-6 lg:px-0 -mx-2 lg:mx-auto`};   
        max-width: 82rem;
        @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
            //Retina Specific
            max-width: 75rem;
        }
    `,
    carSpecs: css`
        opacity: 0.5;
        font-family: ProximaNova-Regular;
        color: #222222;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        margin-right: 7.5px;
        border-box: border;
        font-size: 11px;

        vertical-align: middle;
        &:after{
            display: inline-block;
            content: "|";
            margin-left: 7.5px;
            
        }
        &:last-child:after{
            opacity: 0;
        }
        @media(min-width: 992px){
            font-size: 14px;

        }
    `,
    carTitle: css`
        ${tw`-mt-2 mb-0 lg:my-1`}
        opacity: 0.77;
        font-family: Chromatica-Bold;
        font-size: 25.89px;
        color: #222222;
        letter-spacing: -0.83px;
    `,
    carImage: css`
        height: 20vh;
        ${tw`-ml-2 my-0`}
    `,
    vendorLogo: css`
        ${tw`h-8 lg:h-16`}
    `,
    totalPrice: css`
        font-family: ProximaNova-Bold;
        font-size: 1.618rem;
        @media (min-width: 992px){
            font-size: 41.89px;
        }
        color: #222222;
        letter-spacing: -1.38px;
    `,
    mainTitle: css`
        opacity: 0.9;
        font-family: ProximaNova-Extrabld;
        @media (min-width: 992px){
            font-size: 36.65px;
        }
        font-size: 1.618rem;
        color: #222222;
        letter-spacing: -1.18px;
    `,
    title: css`
        ${tw`w-full flex items-center font-medium justify-between`};
        opacity: 0.65;
        font-size: 18.65px;
        color: #222222;
        letter-spacing: -0.4px;
    `,
    itineraryLine: css`
        ${tw`w-full flex flex-wrap justify-between py-3 lg:py-4 px-6`}
        // border-bottom: 1px solid #F0F2F5;
        p{
            ${tw`w-full my-1 font-sans text-grey-dark`};
            // display: block;
        }
    `,
    choiceFilterButton: css`
         ${tw`flex items-center text-center px-6 py-2 fixed z-50 pin-b pin-x bg-black mx-auto mb-2 md:mb-4 lg:mb-8 justify-between`}; 
        box-shadow: 0 3px 25px rgba(0,0,0,0.2); 
        border-radius: 100px;
        transition: all 0.25s ease-in-out;
        width: 95vw;
        height: 3rem;
        @media (min-width: 992px){
            width: 33vw;
            height: 4rem; 
        }
        &:hover{
            cursor: pointer;
            transform: scale(0.98);
        }
        p{
            ${tw`font-medium text-white text-center`}
            @media(min-width: 992px){
                font-size: 1rem;
            }
        }
    `,
    orderNo: css`
        ${tw`mb-2`}
        opacity: 0.9;
        font-family: Chromatica-Bold;
        @media(min-width: 992px){
            font-size: 30.65px;
        }
        font-size: 18px;
        color: #222222;
        letter-spacing: -1.18px;
    `
}

class confirmed extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            selectedVendor:{},
            selectedCar: {},
            isFullPaid: Boolean,
            amountPaid: 0,
            bookingStatus: 'Confirmed', 
            name: '',
            menuOpen:false,
            cancelReason:'CP',
            completeReward: false,

        }
        this.cancelRide = this.cancelRide.bind(this);
        this.cancelSuccess = this.cancelSuccess.bind(this);
        this.cancelError = this.cancelError.bind(this);
        this.handleLogoClick = this.handleLogoClick.bind(this);
    }
    componentWillMount(){
        // eslint-disable-next-line
        if(typeof analytics === `undefined`){
            return;
        }
        // eslint-disable-next-line
        if (typeof window === `undefined` || typeof gtag === `undefined` ) {
            return;
        }
        // Uncomment this block when rolling to production this is the confirmed conversion

        // eslint-disable-next-line        
        window.dataLayer = window.dataLayer || [];
        // eslint-disable-next-line
        function gtag(){dataLayer.push(arguments);}
        // eslint-disable-next-line
        // gtag('js', new Date());
        // eslint-disable-next-line
        // gtag('config', 'AW-806920639');
        // eslint-disable-next-line
        // gtag('event', 'conversion', {'send_to': 'AW-806920639/U8mZCMrEnYEBEL_D4oAD'});
      
        // gtag('event', 'ecomm_event', {
            // eslint-disable-next-line  
            window.dataLayer.push({
                'event': 'ecomm_event',
                'transactionId': this.props.order.bidId, // Transaction ID - this is normally generated by your system.
                'transactionAffiliation': 'zyppys.com', // Affiliation or store name
                'transactionTotal': this.props.selectedVendor.ride_amt - this.props.selectedVendor.couponValue, // Grand Total
                'transactionTax': 0, // Tax.
                'transactionShipping': 0, // Shipping cost
                'transactionProducts': [
                    {
                        'sku': this.props.rentalType === 1 ? 'Outstation' : (this.props.rentalType === 2 ? 'Self Drive' : (this.props.rentalType === 3 ? 'Ariport Transfer' : 'Hourly Rental')), // SKU/code.
                        'name': this.props.selectedVendor.account_name, // Product name.
                        'category': this.props.selectedCar.category, // Category or variation.
                        'price': this.props.selectedVendor.ride_amt - this.props.selectedVendor.couponValue, // Unit price.
                        'quantity': 1
                    }]
            });

            const options = {
                autoConfig: true, 	// set pixel's autoConfig
                debug: true, 		// enable logs
            };
            const pixelId = '2502221063336049';
            ReactPixel.init(pixelId, options);
            ReactPixel.pageView(); 					// For tracking page view
            // ReactPixel.track( event, data ) 		// For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
            ReactPixel.trackCustom('Confirmed', {'transactionId': this.props.order.bidId} ) 	// For tracking custom events

        // eslint-disable-next-line
        analytics.page("/confirmed",{category:'navigation',label:'Confirmed'});
        // eslint-disable-next-line
        analytics.track("Bid Id",{category:'bid',label:this.props.order.bidId});
        
    }

    componentDidMount(){
        let ride = this.props.ride;
        if(ride.rentalType !==2 && ride.rentalType<=3){
        if(ride.pickup.loc === ""){
            navigate("/");
            return;
        }
    }
    else if(ride.rentalType === 2 || ride.rentalType>3){
        if (!ride.pickup.hasOwnProperty('city') || ride.pickup.city === "") {
            navigate("/");
            return;
        }
    }
        let state = this.props.location.state;
        let selectedVendor = this.props.selectedVendor;
        let selectedCar = this.props.selectedCar;
        this.setState({ selectedVendor, selectedCar })
        this.setState({ amountPaid: state.amountPaid, isFullPaid: state.isFullPaid, name: state.name, completeReward: state.completeReward });
        this.props.dispatch(fetchProfile());
        // For alternating colors for accordion tabs
        console.log( "Selected Vedor", this.props.selectedVendor);
        console.log('Selected Car', this.props.selectedCar);
    }

    zoomCancelSuccess = (data) => {
        //eslint-disable-next-line
        if (confirm(`Are you sure you want to cancel this order? You will be charged ₹${data.cancellationCharges} for this cancellation.`)) {
            this.props.dispatch(cancelRide(this.cancelSuccess, this.cancelError, this.props.order.bidId, this.state.cancelReason));
        }
        else {
            return;
        }
    }

    zoomCancelError = () => {
        //eslint-disable-next-line
        if (confirm('Are you sure you want to cancel this order? You may be charged a slight fee for this cancellation.')) {
            this.props.dispatch(cancelRide(this.cancelSuccess, this.cancelError, this.props.order.bidId, this.state.cancelReason));
        }
        else {
            return;
        }
    }


    cancelRide = () => {
        if (this.state.selectedCar.category === 'SELF DRIVE') {
            if (this.state.selectedVendor.account_name === 'Zoomcar') {
                this.props.dispatch(cancelInitiate(this.props.order.bidId, this.zoomCancelSuccess, this.zoomCancelError));
            }
            else {
                //eslint-disable-next-line
                if (confirm('Are you sure you want to cancel this order? You may be charged a slight fee for this cancellation.')) {
                    this.props.dispatch(cancelRide(this.cancelSuccess, this.cancelError, this.props.order.bidId, this.state.cancelReason));
                }
                else {
                    return;
                }
            }

        }
        else{
            //eslint-disable-next-line
            if(confirm('Are you sure you want to cancel this order? You may be charged a slight fee for this cancellation.')){
                this.props.dispatch(cancelRide(this.cancelSuccess, this.cancelError, this.props.order.bidId, this.state.cancelReason));
            }
            else{
                return;
            }
        }
        
        
    }
    
    cancelSuccess = (data) => {
        // eslint-disable-next-line
        analytics.track("Order Cancelled Successfully",{category:'order',label:'cancelled'});
        alert('Your order has been cancelled successfully');
        this.setState({ bookingStatus: 'Cancelled' });
        this.props.dispatch(fetchProfile());
    }

    cancelError = () => {
        // eslint-disable-next-line
        analytics.track("Cannot Cancel Order",{category:'order',label:'cancel failed'});
        alert('Sorry! cannot cancel your ride at this moment');
    }

    handleLogoClick = () => {
        this.props.dispatch(flushCarsState());
        this.props.dispatch(flushCouponState());
        this.props.dispatch(flushOrderState());
        this.props.dispatch(flushRideState());
        this.props.dispatch(flushTripState());
        // this.props.dispatch(flushUserState());
    }

    render(){
        let ride = this.props.ride;
        if(ride.rentalType !==2 && ride.rentalType<=3){
            if(ride.pickup.loc === ""){
                return null;
            }
        }
        else if(ride.rentalType === 2 || ride.rentalType>3){
            if (!ride.pickup.hasOwnProperty('city') || ride.pickup.city === "") {
                return null;
            }
        }
        let endDate = this.props.ride.endDate;
        let startDate = this.props.ride.startDate;
        let startTime = Moment(this.props.ride.startTime,"HH:mm:ss").format("hh:mm A");
        let endTime = Moment(this.props.ride.endTime,"HH:mm:ss").format("hh:mm A");
        return(
           <AppLayout
                onClick = {this.handleLogoClick}
                name = {this.state.name !== "" ? this.state.name : ''}
           >
                <Helmet
                    links={[
                        {
                            rel: "stylesheet",
                            href: "https://use.typekit.net/iqh3vpp.css"
                        }
                    ]}
                />
                    
                {/* </Helmet> */}
                <div className={styles.row}>
                    <div className={css`${tw`w-full lg:w-1/2 lg:my-4 my-1`}`}>
                        <div className={css`${tw`w-full flex items-center`}`}>
                            <h2 className={styles.mainTitle}>
                                Booking {this.state.bookingStatus}
                            </h2>
                            <img className={css`${tw`mx-4`}`} src={require('../assets/img/confirmed-check.svg')}/>
                        </div>
                        <span className={css`${tw`block -mt-4 text-sm opacity-50 font-sans tracking-normal`}`}>
                            You've booked from {this.state.selectedVendor.account_name}
                        </span>
                    </div>
                    
                    {
                        // this.props.rentalType !== 2 && 
                        this.state.bookingStatus === "Confirmed" && this.state.selectedVendor.account_id !== 6 &&  <Popup
                        trigger={<div className={css`${tw`hidden lg:flex w-full font-medium text-grey-darker rounded lg:w-1/5 lg:my-4 py-4 border-2 border-solid border-grey-light  items-center justify-center cursor-pointer px-4 text-center hover:bg-grey-light`}`}>
                        Cancel Order
                    </div>}
                        position="left top"
                        closeOnDocumentClick
                      >
                      <div className={css`${tw`block ml-2 w-full p-2`}`}>
                      <PaymentRadio
                      value='CP'
                      onClick={(e) => { this.setState({ cancelReason:'CP' }) }}
                      active={this.state.cancelReason === 'CP'}>
                      <div className={css`${tw`inline-flex self-center ml-2`}`}>
                          <span className={css`${tw`block font-normal text-grey-dark text-xl`}`}>
                          Change of plan
     </span>
                      </div>

                  </PaymentRadio> 
                  <PaymentRadio
                  value='PC'
                  onClick={(e) => { this.setState({ cancelReason: 'PC' }) }}
                  active={this.state.cancelReason === 'PC'}>
                  <div className={css`${tw`inline-flex self-center ml-2`}`}>
                      <span className={css`${tw`block font-normal text-grey-dark text-xl`}`}>
                      Price Comparison
 </span>
                  </div>

              </PaymentRadio> 
              <PaymentRadio
                      value='NR'
                      onClick={(e) => { this.setState({ cancelReason: 'NR' }) }}
                      active={this.state.cancelReason === 'NR'}>
                      <div className={css`${tw`inline-flex self-center ml-2`}`}>
                          <span className={css`${tw`block font-normal text-grey-dark text-xl`}`}>
                          No Response from Service Provider
     </span>
                      </div>

                  </PaymentRadio> 
                  <div className={css`${tw` w-full pr-4`}`}>
                  <div onClick={() => this.cancelRide()} className={css`${tw`hidden lg:flex w-20 font-medium text-grey-darker rounded py-4 border-2 border-solid border-grey-light  items-center justify-center cursor-pointer px-4 text-center hover:bg-grey-light`}`}>
                  Proceed
              </div>
              </div>
                      </div>
                     
                      </Popup>
                      }
                </div>

                <div className={styles.row}>
                    <div className={css`${tw`w-full lg:w-1/2 py-2`}`}>
                        <img className={styles.carImage} src={this.state.selectedCar.sub_category_url} />
                        <h3 className={styles.carTitle}>{this.state.selectedVendor.account_name !== "Swing Partner" ? this.state.selectedCar.sub_category : this.state.selectedVendor.vehicle_name}</h3>
                        <div className={css`${tw`flex mx-auto`}`}>
                            <p className={styles.carSpecs}>
                                {this.state.selectedCar.no_of_seats} SEATS
                                </p>
                            {this.props.vendors.category === 'SELF DRIVE' ? 
                                <p className={styles.carSpecs}>
                                    {this.state.selectedVendor.gear_type}
                                </p>: <p></p> }
                            <p className={styles.carSpecs}>
                                {this.props.vendors.category === 'SELF DRIVE' ? this.state.selectedVendor.fuel_type :  ''}
                            </p>
                        </div>
                    </div>
                    <div className={css`${tw`w-full lg:w-1/2 py-2 lg:text-right`}`}>
                        <h3 className={styles.orderNo}>Order #{this.props.order.accountSeqId}</h3>
                        {!this.state.isFullPaid && <p className={css`${tw`text-sm text-grey-darker`}`}>Advance Received, &#8377; {Math.round(this.state.amountPaid).toLocaleString()} </p>}
                        {!this.state.isFullPaid && <p className={css`${tw`text-sm text-grey-darker`}`}>Pending Amount, &#8377; {(this.state.selectedVendor.ride_amt - this.state.selectedVendor.couponValue - this.state.amountPaid).toLocaleString()} </p>}
                        {this.state.isFullPaid && this.state.completeReward === false && <p className={css`${tw`text-sm text-grey-darker`}`}>Amount Received, &#8377; {Math.round(this.state.selectedVendor.ride_amt - this.state.selectedVendor.couponValue).toLocaleString()} </p>}
                        {this.state.isFullPaid  && this.state.completeReward === true && <p className={css`${tw`text-sm text-grey-darker`}`}>Amount Received through Reward Points, &#8377; {Math.round(this.state.selectedVendor.ride_amt - this.state.selectedVendor.couponValue).toLocaleString()} </p>}

                    </div>
                </div>

                <div className={styles.row} style={{marginTop: '1rem'}}>
                    <AccordionItem title={'What to Expect Now?'}>

                        <div className={styles.itineraryLine}>
                            <p>You will receive a follow up call with {this.state.selectedVendor.account_name}.</p>
                            {this.state.selectedCar.category === "SELF DRIVE" && <p>For the order to be completed, you need to have a valid Drivers License and an ID proof.</p>}
                            {this.state.selectedCar.category === "SELF DRIVE" && <p>You must be above 21 years of age</p>}
                        </div>

                    </AccordionItem>
                    <AccordionItem title="Itinerary">
                        <div className={styles.itineraryLine}>
                            <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Pickup City</p>
                            <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>{ride.pickup.city}</p>
                        </div>
                        <div className={styles.itineraryLine}>
                            <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Pickup Location</p>
                            <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>{ride.pickup.loc}</p>
                        </div>
                        {this.props.ride.rentalType < 3 && <div className={styles.itineraryLine}>
                            <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Destination City</p>
                            <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>{ride.dest.city}</p>
                        </div>}
                        <div className={styles.itineraryLine}>
                            <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Start Date</p>
                            <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>{Moment(startDate,"DD-MM-YYYY").format('DD MMM, YYYY')+' '+startTime}</p>
                        </div>
                        {this.state.selectedVendor.measure_lookup_id === 100010001 || this.state.selectedCar.category === "SELF DRIVE" && <div className={styles.itineraryLine}>
                            <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>{this.state.selectedVendor.measure_cd === 'ONEWAY'  ? '': 'End Date'}</p>
                            <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>{this.state.selectedVendor.measure_cd === 'ONEWAY'  ? '': Moment(endDate,"DD-MM-YYYY").format('DD MMM, YYYY') + ' ' + endTime}</p>
                        </div>}
                    </AccordionItem>
                    <AccordionItem title="Important Terms You Should Know">
                            {
                                this.state.selectedVendor.hasOwnProperty('terms_conditions')
                                ?
                                this.state.selectedVendor.terms_conditions.map((terms)=>{
                                    return(
                                    <p className={css`${tw`block w-full px-6 leading-loose text-grey-dark`}`}>{terms}</p>
                                    );
                                })
                                :
                                    <a href={this.state.selectedVendor.terms_conditions_url}>{this.state.selectedVendor.terms_conditions_url}</a>
                            }
                        </AccordionItem>
                        <AccordionItem title="Price Breakdown">
                        {this.props.vendors.category !== 'SELF DRIVE'?
                        
                            <div className={styles.itineraryLine}>
                                <div className={styles.itineraryLine}>
                                        <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Base Fare
                                            {this.state.selectedVendor.package_kms * this.state.selectedVendor.actual_cost_per_km === this.state.selectedVendor.base_fare && <span className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker block opacity-60 py-2`}`}> {this.state.selectedVendor.package_kms.toLocaleString()} Kms x &#8377; {this.state.selectedVendor.actual_cost_per_km.toLocaleString()}</span>}
                                         </p>
                                        <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {Math.round(this.state.selectedVendor.base_fare).toLocaleString()}</p>
                                </div>

                                {this.state.selectedVendor.hasOwnProperty("driver_charges_per_day") && this.state.selectedVendor.driver_charges_per_day!==null && this.state.selectedVendor.driver_charges_per_day.toLocaleString() &&
                                <div className={styles.itineraryLine}>
                                    <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Driver Charges 
                                        <span className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker block opacity-60 py-2`}`}>&#8377; {this.state.selectedVendor.driver_charges_per_day.toLocaleString()} x {this.state.selectedVendor.no_of_days} Days </span>
                                    </p>
                                    <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {Math.floor(this.state.selectedVendor.driver_charges_per_day * this.state.selectedVendor.no_of_days).toLocaleString()}</p>   
                                </div>}

                                {this.state.selectedVendor.hasOwnProperty("night_charges") && this.state.selectedVendor.night_charges !== null && this.state.selectedVendor.night_charges > 0 &&
                                <div className={styles.itineraryLine}>
                                    <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Night Charges </p>
                                    <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {this.state.selectedVendor.night_charges.toLocaleString()} </p>
                                </div>
                                }
                                
                                {this.state.selectedVendor.discountedValue!==this.state.selectedVendor.ride_amt &&
                                <div className={styles.itineraryLine}>
                                    <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Coupon Discount </p>
                                    <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>-&#8377; {(this.state.selectedVendor.ride_amt-this.state.selectedVendor.discountedValue).toLocaleString()}</p>
                                </div>
                                }

                                {this.state.selectedVendor.hasOwnProperty("service_tax_amt") && this.state.selectedVendor.service_tax_amt!==null && parseInt(this.state.selectedVendor.service_tax_amt)>0 &&
                                <div className={styles.itineraryLine}>
                                    <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>GST</p>
                                    <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {this.state.selectedVendor.service_tax_amt.toLocaleString()}</p>
                                </div>
                                }

                                {this.state.selectedVendor.hasOwnProperty("state_tax") && this.state.selectedVendor.state_tax>0 && parseInt(this.state.selectedVendor.state_tax)>0 &&
                                    <div className={styles.itineraryLine}>
                                        <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Border Tax</p>
                                        <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {this.state.selectedVendor.state_tax.toLocaleString()}</p>
                                    </div>
                                }

                                {this.state.selectedVendor.hasOwnProperty("service_charges") && this.state.selectedVendor.service_charges!==null && parseInt(this.state.selectedVendor.service_charges)>0 &&
                                    <div className={styles.itineraryLine}>
                                        <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Service Charges</p>
                                        <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {this.state.selectedVendor.service_charges.toLocaleString()}</p>
                                    </div>
                                }

                                {this.state.selectedVendor.hasOwnProperty("toll_tax") && this.state.selectedVendor.toll_tax!==null && parseInt(this.state.selectedVendor.toll_tax)>0 &&
                                    <div className={styles.itineraryLine}>
                                        <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Tolls</p>
                                        <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {this.state.selectedVendor.toll_tax.toLocaleString()}</p>
                                    </div>
                                }

                                {this.state.selectedVendor.hasOwnProperty("extra_cost_per_km") && this.state.selectedVendor.extra_cost_per_km!==null && parseInt(this.state.selectedVendor.extra_cost_per_km) > 0 &&
                                <div className={styles.itineraryLine}>
                                    <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Extra Cost per Km</p>
                                    <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {this.state.selectedVendor.extra_cost_per_km.toLocaleString()}</p>
                                </div>
                                }
                               
                        </div>
                            
                        :
                    
                    <div className={styles.itineraryLine}>
                        <div className={styles.itineraryLine}>
                            <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Base Fare
                                <span className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker block opacity-60 py-2`}`}>{typeof this.state.selectedVendor.package_kms === 'string'? this.state.selectedVendor.package_kms :this.state.selectedVendor.package_kms} {typeof this.state.selectedVendor.package_kms === 'string' && (this.state.selectedVendor.package_kms.includes('kms')||this.state.selectedVendor.package_kms.includes('Kms')) ? '': 'Kms'}</span>
                            </p>
                            <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {Math.round(this.state.selectedVendor.base_fare).toLocaleString()}</p>                          
                        </div>

                        {this.state.selectedVendor.hasOwnProperty("security_deposit") && this.state.selectedVendor.security_deposit!==null && parseInt(this.state.selectedVendor.security_deposit) > 0 &&
                            <div className={styles.itineraryLine}>
                                <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Security Deposit</p>
                                <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {this.state.selectedVendor.security_deposit.toLocaleString()}</p>
                            </div>
                        }

                        {this.state.selectedVendor.hasOwnProperty("service_tax_amt") && this.state.selectedVendor.service_tax_amt!==null && parseInt(this.state.selectedVendor.service_tax_amt) > 0 &&
                            <div className={styles.itineraryLine}>
                                <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>GST</p>
                                <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {this.state.selectedVendor.service_tax_amt.toLocaleString()}</p>
                            </div>
                        }

                        {this.state.selectedVendor.hasOwnProperty("delivery_charges") && this.state.selectedVendor.delivery_charges!==null && parseInt(this.state.selectedVendor.delivery_charges) > 0 &&
                            <div className={styles.itineraryLine}>
                                <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Delivery Charges</p>
                                <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {this.state.selectedVendor.delivery_charges.toLocaleString()}</p>
                            </div>
                        }

                        {this.state.selectedVendor.hasOwnProperty("extra_cost_per_km") && this.state.selectedVendor.extra_cost_per_km!==null && parseInt(this.state.selectedVendor.extra_cost_per_km) > 0 &&
                                <div className={styles.itineraryLine}>
                                    <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Extra Cost per Km</p>
                                    <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {this.state.selectedVendor.extra_cost_per_km.toLocaleString()}</p>
                                </div>
                        }

                        {this.state.selectedVendor.hasOwnProperty("extra_cost_per_hr") && this.state.selectedVendor.extra_cost_per_hr!==null && parseInt(this.state.selectedVendor.extra_cost_per_hr) > 0 &&
                                <div className={styles.itineraryLine}>
                                    <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Extra Cost per Hr</p>
                                    <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {this.state.selectedVendor.extra_cost_per_hr.toLocaleString()}</p>
                                </div>
                        }

                                {this.state.selectedVendor.hasOwnProperty("pickup_charges") && this.state.selectedVendor.pickup_charges !== null &&
                                    <div className={styles.itineraryLine}>
                                        <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Pickup Charges</p>
                                        <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {this.state.selectedVendor.pickup_charges.toLocaleString()}</p>
                                    </div>
                                }
                                {this.state.selectedVendor.hasOwnProperty("dropoff_charges") && this.state.selectedVendor.dropoff_charges !== null &&
                                    <div className={styles.itineraryLine}>
                                        <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Dropoff Charges</p>
                                        <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {this.state.selectedVendor.dropoff_charges.toLocaleString()}</p>
                                    </div>
                                }
                                {this.state.selectedVendor.hasOwnProperty("service_charges") && this.state.selectedVendor.service_charges !== null && parseInt(this.state.selectedVendor.service_charges) > 0 &&
                                    <div className={styles.itineraryLine}>
                                        <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Additional Service Charges</p>
                                        <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {this.state.selectedVendor.service_charges.toLocaleString()}</p>
                                    </div>
                                }

                    </div>
                    }
                        </AccordionItem>
                </div>
                {
                    // this.props.rentalType !== 2 && 
                <div className={styles.row}>
                    {/* <div className={css`${tw`hidden lg:block w-full bg-grey-light lg:my-12 my-4 mx-auto px-6 opacity-50`}; height: 1px;`}></div> */}
                    {this.state.bookingStatus === "Confirmed" && this.state.selectedVendor.account_id !== 6 && 
                    
                    <Popup
                    trigger={<div className={css`${tw`w-full font-medium text-grey-darker rounded lg:w-1/5 lg:my-4 py-4 border-2 border-solid border-grey-light  items-center justify-center cursor-pointer px-4 text-center hover:bg-grey-light`}`}>
                            Cancel Order
                        </div>}
                    position="top center"
                    closeOnDocumentClick
                  >
                  <div className={css`${tw`block ml-2 w-full p-2`}`}>
                  <PaymentRadio
                  value='CP'
                  onClick={(e) => { this.setState({ cancelReason:'CP' }) }}
                  active={this.state.cancelReason === 'CP'}>
                  <div className={css`${tw`inline-flex self-center ml-2`}`}>
                      <span className={css`${tw`block font-normal text-grey-dark text-xl`}`}>
                      Change of plan
 </span>
                  </div>

              </PaymentRadio> 
              <PaymentRadio
              value='PC'
              onClick={(e) => { this.setState({ cancelReason: 'PC' }) }}
              active={this.state.cancelReason === 'PC'}>
              <div className={css`${tw`inline-flex self-center ml-2`}`}>
                  <span className={css`${tw`block font-normal text-grey-dark text-xl`}`}>
                  Price Comparison
</span>
              </div>

          </PaymentRadio> 
          <PaymentRadio
                  value='NR'
                  onClick={(e) => { this.setState({ cancelReason: 'NR' }) }}
                  active={this.state.cancelReason === 'NR'}>
                  <div className={css`${tw`inline-flex self-center ml-2`}`}>
                      <span className={css`${tw`block font-normal text-grey-dark text-xl`}`}>
                      No Response from Service Provider
 </span>
                  </div>

              </PaymentRadio> 
              <div className={css`${tw` w-full pr-4`}`}>
              <div onClick={() => {
                // eslint-disable-next-line
                analytics.track("Cancel Pressed");
                this.cancelRide()}} className={css`${tw`lg:flex w-20 font-medium text-grey-darker rounded py-4 border-2 border-solid border-grey-light  items-center justify-center cursor-pointer px-4 text-center hover:bg-grey-light`}`}>
              Proceed
          </div>
          </div>
                  </div>
                 
                  </Popup>
                }
                </div>
                    }
                

           </AppLayout>
           
        );
    }
}


const mapStateToProps = (state) => ({
    selectedVendor: state.order.selectedVendor,
    selectedCar: state.order.selectedCar,
    vendors: state.cars.vendors,
    ride: state.ride,
    order: state.order,
    rentalType: state.ride.rentalType,
})

export default connect(mapStateToProps)(confirmed);